import { ConfigProvider, ThemeConfig } from 'antd';
import React from "react"
import ErrorBoundary from './src/components/ErrorBoundary';
import FeniciaContextProvider from "./src/context/FeniciaContextProvider"
import type { GatsbyBrowser } from "gatsby"
import './src/styles/global.css'

const theme : ThemeConfig = {
  token:{
    colorPrimary:"#ff005d",
    borderRadius: 8
  }
}

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
})  => (
  <ErrorBoundary>
  <FeniciaContextProvider>
    <ConfigProvider theme={theme}>
    {element}
    </ConfigProvider>
  </FeniciaContextProvider>    
  </ErrorBoundary>
)